import React from 'react';
import { useNavigate } from 'react-router-dom';
function TeamSection({ homePageData }) {
    const navigate=useNavigate()
    const teamClickHanlder = () => {
        navigate("/about")
    }
    return (
        <section className="team-redirect-section">
            <div className='container'>
                <div className="team-redirect-content">
                    <h1>{homePageData.acf.team_tittle_section}</h1>
                    <button className="white-button team-redirect-button" onClick={teamClickHanlder}>{homePageData.acf.team_button_section}</button>
                </div>
            </div>
        </section>
    );
}

export default TeamSection;
