import React, { createContext, useContext } from 'react'
export const ContextSnippet = createContext(null);
export const useContextSnippet = () => {
    return useContext(ContextSnippet);
}







