import React from 'react'
import { useNavigate } from 'react-router-dom'
const HeroSection = ({ homePageData }) => {
  const navigate = useNavigate()
  const ServiceClickHanlder = () => {
    navigate('/service')
  }

  return (
    <section className="hero-section">
      <div className='container'>
        <div className='hero-main'>
        <div className="hero-text">
            {homePageData && (
              <>
                <h1
                  dangerouslySetInnerHTML={{ __html: homePageData.acf.top_section_title }}
                />
                <p dangerouslySetInnerHTML={{ __html: homePageData.acf.brief_description }}></p>
              </>
            )}
          </div>
          <div className="hero-buttons">
            <a href="https://calendar.app.google/sCKUN89JR5o3oUi98" target='_blank'>
              <button className="white-button hero-button" >{homePageData.acf.top_section_button1}</button>
            </a>

            <button className="white-button hero-button" onClick={ServiceClickHanlder}>{homePageData.acf.top_section_button_2}</button>
          </div>
          </div>
        </div>
    </section>
  )
}
export default HeroSection

