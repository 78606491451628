import React from 'react';
import { useNavigate } from 'react-router-dom';

function ServiceLook({ ServicePageData }) {
    const navigate=useNavigate();
    const ourTeamClickHandler = () => {
        navigate("/contact")
    }
    return (
        <section className="serviceLook-section">
            <div className='container'>
                <div className="serviceLook-content">
                    <h1 className="serviceLook-title">{ServicePageData.acf.look_title}</h1>
                    <button className="serviceLook-button" onClick={ourTeamClickHandler}>{ServicePageData.acf.look_button}</button>
                </div>
            </div>
        </section>
    );
}

export default ServiceLook;

