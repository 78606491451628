import React from 'react';

function ServiceHero({ ServicePageData }) {
    return (
        <section className="hero-section">
            <div className='container'>
                <div className="hero-main">
                    <div className='hero-text'>
                    <h1 dangerouslySetInnerHTML={{ __html: ServicePageData.acf.service_top_section }}></h1>
                    <p dangerouslySetInnerHTML={{ __html: ServicePageData.acf.service_brief_section }}></p>
                </div>
                </div>
            </div>
        </section>




    );
}
export default ServiceHero;
