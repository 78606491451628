import React from 'react';
function DiscoverySection({ homePageData }) {
    const bookClickHanlder = () => {
        console.log("Clicked Chat button");
    }
    console.log(homePageData.acf.discovery_top_section);
    let removeSemicolonFromText = homePageData.acf.discovery_top_section.replace(/"/g, '');
    return (
        <section className='discovery-section'>
            <div className='container'>
                <div className='discovery-main'>
                    <div className="discovery-image">
                        <img srcSet={homePageData.acf.discovery_} />
                    </div>
                    <div className="discovery-content">      
                    <div className="discovery-description" dangerouslySetInnerHTML={{ __html: homePageData.acf.discovery_top_section }} />   
                        <a href="https://calendar.app.google/pY9pc87DEZZYaPZk6" target="_blank" rel="noopener noreferrer">
                            <button className="white-button discovery-button" onClick={bookClickHanlder}>{homePageData.acf.discovery_button}</button>
                        </a>
                        </div>
                    </div>
                    </div>
        </section>
           
    );
}

export default DiscoverySection;
