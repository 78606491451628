import React from 'react';
function AboutHero({ AboutPageData }) {
    return (
        <section className="hero-section">
            <div className='container'>
             <div className='hero-main'>
                <div className="hero-text">
                    <h1 dangerouslySetInnerHTML={{__html:AboutPageData.acf.About_Top_Section}}></h1>
                    <p dangerouslySetInnerHTML={{__html:AboutPageData.acf.about_brief_section}}></p>
                </div>
            </div>
            </div>
        </section>
    );
}

export default AboutHero;
