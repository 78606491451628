import React, { useEffect, useState } from 'react'
import ServiceHero from '../ServicePage/ServiceHero'
import ServiceBrand from '../ServicePage/ServiceBrand'
import ServiceApproach from '../ServicePage/ServiceApproach'
import ServiceLook from '../ServicePage/ServiceLook'
import FooterMain from '../FooterPage/FooterMain'
import { useContextSnippet } from '../context/ContextApi'
import axios from 'axios'
import Loading from './Loading'
function Service() {
    const apiUrl = useContextSnippet();
    const [ServicePageData, setServicePageData] = useState(null);
    useEffect(() => {
        const fetchServicepageData = async () => {
            try {
                const ServicepageResponse = await axios.get(`${apiUrl}/pages/37`);
                setServicePageData(ServicepageResponse.data);
            } catch (error) {
                console.error('Error fetching Servicepage data:', error);
            }
        };
        fetchServicepageData();
    }, [apiUrl]);
    return (
        <>
            {ServicePageData ? (<>
                <ServiceHero ServicePageData={ServicePageData} />
                <ServiceBrand ServicePageData={ServicePageData} />
                <ServiceApproach ServicePageData={ServicePageData} />
                <ServiceLook ServicePageData={ServicePageData} />
                <FooterMain ServicePageData={ServicePageData} />
            </>) : (<Loading />)}
        </>
    )
}
export default Service