import React from 'react'
import Header from '../NavigationPage/Header'

function Nav() {
    return (
        <>
            <Header />
        </>
    )
}

export default Nav