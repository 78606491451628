import React, { useEffect, useState } from 'react'
import WorkHero from '../WorkPage/WorkHero'
import WorkNav from '../WorkPage/WorkNav'
import FooterMain from '../FooterPage/FooterMain'
import { useContextSnippet } from '../context/ContextApi'
import axios from 'axios'
import Loading from './Loading'
function OurWork() {
  const apiUrl = useContextSnippet();
  const [WorkPageData, setWorkPageData] = useState(null);
  useEffect(() => {
    const fetchWorkpageData = async () => {
      try {
        const WorkpageResponse = await axios.get(`${apiUrl}/pages/50`);
        setWorkPageData(WorkpageResponse.data);
      } catch (error) {
        console.error('Error fetching Workpage data:', error);
      }
    };
    fetchWorkpageData();
  }, [apiUrl]);
  return (
    <>
      {WorkPageData ? (<>
        <WorkHero WorkPageData={WorkPageData} />
        <WorkNav WorkPageData={WorkPageData} />
        <FooterMain WorkPageData={WorkPageData} />
      </>) : (<Loading />)}

    </>
  )
}
export default OurWork