import React, { useEffect, useState } from 'react'
import AboutHero from '../AboutPage/AboutHero'
import AboutGrow from '../AboutPage/AboutGrow'
import FooterMain from '../FooterPage/FooterMain'
import { useContextSnippet } from '../context/ContextApi'
import axios from 'axios'
import Loading from './Loading'
import StatureTeam from '../AboutPage/StatureTeam'
import AboutDiscover from '../AboutPage/AboutDiscover'
function About() {
  const apiUrl = useContextSnippet();
  const [AboutPageData, setAboutPageData] = useState(null);
  useEffect(() => {
    const fetchAboutpageData = async () => {
      try {
        const AboutpageResponse = await axios.get(`${apiUrl}/pages/2`);
        setAboutPageData(AboutpageResponse.data);
      } catch (error) {
        console.error('Error fetching Aboutpage data:', error);
      }
    };
    fetchAboutpageData();
  }, [apiUrl]);
  return (
    <>
      {AboutPageData ? (<>
        <AboutHero AboutPageData={AboutPageData} />
        <AboutGrow AboutPageData={AboutPageData} />
        <StatureTeam AboutPageData={AboutPageData} />
        <AboutDiscover AboutPageData={AboutPageData} />
        <FooterMain AboutPageData={AboutPageData} />
      </>) : (<Loading />)}

    </>
  )
}
export default About