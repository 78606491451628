import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useContextSnippet } from '../context/ContextApi';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import videFile from "../assets/keith_review.mp4";

export default function CrouselStudy({ homePageData }) {
    const apiUrl = useContextSnippet();
    const navigate = useNavigate();
    const [crousalData, setCrousalData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const sliderRef = useRef(null);

    const reviewClickHandler = (item) => {
        setSelectedItem(item);
        setShowPopup(true);
    };

    const workClickHandler = () => {
        navigate("/our-work");
    };

    useEffect(() => {
        const fetchCrousalData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/portfolio?_embed&orderby=menu_order&order=asc`);
                setCrousalData(response.data);
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
            }
        };
        fetchCrousalData();
    }, [apiUrl]);

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow prev-arrow" onClick={onClick}>
            <span className="arrow-icon">←</span>
        </div>
    );

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow next-arrow" onClick={onClick}>
            <span className="arrow-icon">→</span>
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <div />, 
        nextArrow: <div />, 
    };

    const closePopup = () => {
        setShowPopup(false);
        setSelectedItem(null);
    };

    return (
        <section className="crousel-study">
            <div className='container' style={{textAlign: 'center'}}>
            <div className="video_review">
                <iframe
                    src="https://www.youtube.com/embed/u4qGq1zgYTI"
                    frameBorder="0"
                    allowFullScreen
                    title="Keith Review"
                ></iframe>
            </div>
            {/* <video width="80%" controls>
                <source src={videFile} type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
                {/* <Slider ref={sliderRef} {...settings}>
                    {crousalData && crousalData.map((item, index) => (
                        <div className='crousel-study-main' key={index}>
                            <div className="crousel-study-text">
                                <div className="text-crousel">
                                    <h3>{item.title.rendered}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}></p>
                                </div>
                                <div className="crousel-button-section">
                                <div className="case-study-buttons">
                                    <button className="case-study-button" onClick={() => reviewClickHandler(item)}>{homePageData.acf.crousel_button_section1}</button>
                                    <button className="case-study-button" onClick={workClickHandler}>{homePageData.acf.crousel_button_section_2}</button>
                                 </div>
                                 <div className="arrow-button">  
                                     <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
                                    <NextArrow onClick={() => sliderRef.current.slickNext()} />
                                </div>
                                
                                </div>
                            </div>
                            <div className="crousel-study-image">
                                <div className="crousel-image-box">
                                    <img srcSet={item._embedded?.["wp:featuredmedia"]?.[0]?.source_url} alt="Bookshop Day" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

                {showPopup && selectedItem && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <span className="popup-close" onClick={closePopup}>X</span>
                            <div className="main-pop-up">
                                <div className="popup-image">
                                    <img srcSet={selectedItem.acf.client_image} alt={selectedItem.title.rendered} loading="lazy" />
                                </div>
                                <div className="popup-text">
                                    {selectedItem.acf.client_name &&
                                        <h3 className="popup-title">{selectedItem.acf.client_name}</h3>
                                    }
                                    {selectedItem.acf.client_review &&
                                        <p className="popup-description">{selectedItem.acf.client_review}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </section>
    );
}
