import React from "react";

const Loading = () => {
    return (
        <figure className="loading-container">
            <div className="dot white"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </figure>
    );
};

export default Loading;