import React, { useEffect, useState } from "react";
import { useContextSnippet } from "../context/ContextApi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function FooterMain() {
  const navigate = useNavigate();

  const apiUrl = useContextSnippet();
  const [footerPageData, setFooterPageData] = useState(null);
  useEffect(() => {
    const fetchfooterpageData = async () => {
      try {
        const footerpageResponse = await axios.get(`${apiUrl}/pages/52`);
        setFooterPageData(footerpageResponse.data);
      } catch (error) {
        console.error("Error fetching footerpage data:", error);
      }
    };
    fetchfooterpageData();
  }, [apiUrl]);

  const homeClickHandler = () => {
    navigate("/");
  };

  const aboutClickHanlder = () => {
    navigate("/about");
  };

  const ContactClickHandler = () => {
    navigate("/contact");
  };

  const footerCLickHandler = () => {
    console.log("Clicked by footer button");
  };
  return (
    <>
      {footerPageData && (
        <footer className="footer">
          <div className="container">
            <div className="footer-main">
              <div className="footer-section footer-about">
                <div
                  dangerouslySetInnerHTML={{
                    __html: footerPageData.acf.footer_phase1,
                  }}
                />
                {/* <button
                  className="white-button footer-button"
                  onClick={footerCLickHandler}
                >
                  {footerPageData.acf.footer_phase1_button}
                </button> */}
              </div>

              <div className="footer-section footer-links">
                <li className="footer-links-item"
                  dangerouslySetInnerHTML={{
                    __html: footerPageData.acf.footer_phase2,
                  }}
                />
                <li className="footer-links-item" onClick={homeClickHandler}
                  dangerouslySetInnerHTML={{
                    __html: footerPageData.acf.quick_links_button_first,
                  }}
                />
                 <li className="footer-links-item"  onClick={aboutClickHanlder}
                  dangerouslySetInnerHTML={{
                    __html: footerPageData.acf.quick_links_button_second,
                  }}
                />
                 <li className="footer-links-item"   onClick={ContactClickHandler}
                  dangerouslySetInnerHTML={{
                    __html: footerPageData.acf.quick_links_button_third,
                  }}
                />
              </div>

              <div
                className="footer-section footer-contact"
                dangerouslySetInnerHTML={{
                  __html: footerPageData.acf.footer_phase3,
                }}
              />

              <div className="footer-section footer-social">
                <div
                  dangerouslySetInnerHTML={{
                    __html: footerPageData.acf.footer_phase4,
                  }}
                />
                <div className="Social-links">
                  <div className="linkedin">
                    <a href="https://www.linkedin.com/company/team-stature/?viewAsMember=true">
                      <img srcset={footerPageData.acf.indeed_icon} />
                    </a>
                  </div>
                  <div className="instagram">
                    <a href="https://www.instagram.com/team_stature/">
                      <img srcset={footerPageData.acf.insta_icon} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-title">
              <span className="footer-title-word">STATURE</span>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default FooterMain;
