import React from 'react'
import { ContextSnippet } from './ContextApi'

function ContextProvider({ children }) {
    const apiUrl = 'https://api.statureit.com/wp-json/wp/v2';
    return (
        <ContextSnippet.Provider value={apiUrl}>
            {children}
        </ContextSnippet.Provider>
    )
}

export default ContextProvider