import React from 'react';

function StatureTeam({ AboutPageData }) {
    let teamData = AboutPageData.acf.add_team_members;
    console.log(teamData);

    return (
        <section className="team-container1">
            <div className="container">
                <h2 style={{ color: "#347c64" }}>{AboutPageData.acf.team_title}</h2>
                <br />
                <br />
                <div className="team-container">
                    {teamData.map((member, i) => (
                        <div key={i} className="team-card">
                            <div className="team-image">
                                {member.team_image && <img srcSet={member.team_image} alt={member.about_team_member} />}
                            </div>
                            <div className="team-card-content">
                                <h3>{member.about_team_member}</h3>
                                <p>{member.member_designation}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
export default StatureTeam;


