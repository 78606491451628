import React from 'react';

function AboutGrow({ AboutPageData }) {
    let moreAboutUs = AboutPageData.acf.more_about_us_in_points;
    return (
        <section className="about-grow-section">
            <div className='container'>
                <div className="about-grow-text" style={{textAlign: 'center'}}>
                    <h3 className="about-grow-heading">{AboutPageData.acf.about_grow_title}</h3>
                    <p className="about-grow-description">{AboutPageData.acf.grow_description}</p>
                </div>
                <div className="about-grow-cards">
                    {moreAboutUs.map((item, index) => (
                        <div key={index} className="about-grow-card">
                            <h4 className="about-grow-card-title" dangerouslySetInnerHTML={{ __html: item.who_we_are_in_detail}}/>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default AboutGrow;


