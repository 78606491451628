import React from "react";
import { useNavigate } from "react-router-dom";
function Member({ homePageData }) {
    const navigate = useNavigate()
    const Member_img = homePageData.acf.member_img_section;
    let achievements = homePageData.acf.our_achievements;
    const workClickHandler = () => {
        navigate("/about")
    }
    return (
        <>
        <section className="member-section1">
            <div className='container'>
                <div className='member-section'>
                    <div className="member-content">
                        <div className="member-text">
                        <div  dangerouslySetInnerHTML={{ __html: homePageData.acf.member_top_section}}> 
                        </div>
                        </div>
                        <div className="brand-footer">
                            <button className="white-button member-button" onClick={workClickHandler}>{homePageData.acf.member_button_section}</button>
                        </div>    
                    </div>
                    <div className="member-image">
                        <img srcSet={Member_img} alt="Example" />
                    </div>
                </div>
            </div>
        </section>

        <section className="crousel-study">
            <div className='container' style={{textAlign: 'center'}}>
            <div className="video_review">
                <iframe src="https://www.youtube.com/embed/Md1o1Ea3w7M"  title="bryan Review">  </iframe>
            </div>
            </div>
        </section>

        <section className="our-achievements">
    <div className="container">
        {achievements.map((achievement, i) => (
            <div key={i} className="achievement-section">
                <div className="achievement-content">

                    <div className="achievement_img">
                        <img srcSet={achievement.achievement_img} alt="Achievement" />
                    </div> 
                    <div className="achievement-text" style={{textAlign: 'left', margin: '2rem'}} dangerouslySetInnerHTML={{ __html: achievement.achievement_brief }} />
                  
                    <div className="visit-button " style={{marginTop: '2rem', marginLeft: '2rem'}}>
                        <a href="https://www.upwork.com/fl/~0130f1bc54a091acaa?mp_source=share" target="_blank">Hire now</a>  
                    </div>
                    </div>
            </div>
        ))}
    </div>
</section>
    </>
    );
}

export default Member;
