import React from 'react';

function ContactHero({ contactPageData }) {
    return (
        <section className="hero-section">
            <div className='container'>
                <div className='hero main'>
                <div className='hero-text'>
                    <h1 dangerouslySetInnerHTML={{__html:contactPageData.acf.contact_top_section}}></h1>
                    <p  dangerouslySetInnerHTML={{__html:contactPageData.acf.contact_brief_section}}></p>
                </div>
            </div>
            </div>
        </section>
    );
}

export default ContactHero;
