import React from 'react';
function WorkHero({ WorkPageData }) {
    return (
        <section className="hero-section">
            <div className='container'>
                <div className="hero-main">
                    <div className='hero-text'>
                    <h1 dangerouslySetInnerHTML={{ __html: WorkPageData.acf.work_top_section }}></h1>
                    <p dangerouslySetInnerHTML={{ __html: WorkPageData.acf.work_brief_section }}></p>
                </div>
                </div>
            </div>

        </section>
    );
}

export default WorkHero;


