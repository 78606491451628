import React from 'react';
function ContactCard({ contactPageData }) {
    const Contactdata = [
        { "img": contactPageData.acf.card_img1, "Contact": contactPageData.acf.card_titl11, "address": contactPageData.acf.card_email1 },
        { "img": contactPageData.acf.card_img2, "Contact": contactPageData.acf.card_title2, "address": contactPageData.acf.phone_number },
        { "img": contactPageData.acf.card_img3, "Contact": contactPageData.acf.card_title3, "address": contactPageData.acf.card_address },
    ];
    return (
        <>
            <section className='contact-card-page'>
                <div className='container'>
                    <div className="contact-cards">
                        {Contactdata.map((item, index) => (
                            <div key={index} className="contact-card">
                                <div className="contact-card-img">
                                    <img srcSet={item.img} alt={item.Contact} loading="lazy" />
                                </div>

                                <div className="contact-card-info">
                                    <h3 className="contact-card-title">{item.Contact}</h3>
                                    <p className="contact-card-address">{item.address}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>

    );
}

export default ContactCard;

