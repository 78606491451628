import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

function ContactForm({ contactPageData }) {
    const [data, setData] = useState({
        Name: "",
        Company: "",
        Email: "",
        Phone: "",
        Message: ""
    });

    const [loading, setLoading] = useState(false);

    const serviceId = "service_yzy1hjd";
    const templateId = "template_u4kxgkf";
    const publicKey = "RivurSWXRk7ls5lxs";

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData(prevData => ({ ...prevData, [name]: value }));
    };

    const validatePhoneNumber = (phone) => {
        return phone.length === 10 && /^\d+$/.test(phone);
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        if (!validatePhoneNumber(data.Phone)) {
            toast.error("Phone number must be exactly 10 digits.");
            return;
        }

        setLoading(true);

        const templateParams = {
            from_name: data.Name,
            from_email: data.Email,
            to_name: "Team Stature",
            from_phone: data.Phone,
            message: data.Message
        };

        try {
            const response = await emailjs.send(serviceId, templateId, templateParams, publicKey);
            console.log("Email sent successfully", response);
            toast.success("Form submitted successfully!");
            setData({
                Name: "",
                Company: "",
                Email: "",
                Phone: "",
                Message: ""
            });
        } catch (error) {
            console.error("Error sending Email:", error);
            toast.error("Failed to send email. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="contact-form-section">
            <div className='container'>
                <div className="contact-form-intro">
                    <h3>{contactPageData.acf.form_title}</h3>
                </div>

                <form className="contact-form" onSubmit={onSubmitHandler}>
                    <div className='form-main-group'>
                        <div className="form-group">
                            <label htmlFor="fullName">{contactPageData.acf.form_name}</label>
                            <input
                                type="text"
                                id="fullName"
                                name="Name"
                                required
                                value={data.Name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="company">{contactPageData.acf["form-company"]}</label>
                            <input
                                type="text"
                                id="company"
                                name="Company"
                                value={data.Company}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='form-main-group'>
                        <div className="form-group">
                            <label htmlFor="email">{contactPageData.acf.form_email}</label>
                            <input
                                type="email"
                                id="email"
                                name="Email"
                                required
                                value={data.Email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">{contactPageData.acf.form_phone}</label>
                            <input
                                type="tel"
                                id="phone"
                                name="Phone"
                                pattern="\d{10}"
                                value={data.Phone}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group-message">
                        <label htmlFor="message">{contactPageData.acf["form-message"]}</label>
                        <textarea
                            id="message"
                            name="Message"
                            rows="4"
                            required
                            value={data.Message}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <button className='form-submit' type="submit" disabled={loading}>
                        {loading ? 'Sending...' : 'Send'}
                    </button>
                </form>
                <ToastContainer />
            </div>
        </section>
    );
}

export default ContactForm;

