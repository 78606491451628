import React, { useEffect, useState } from 'react'
import ContactHero from '../ContactPage/ContactHero'
import ContactCard from '../ContactPage/ContactCard'
import ContactForm from '../ContactPage/ContactForm'
import FooterMain from '../FooterPage/FooterMain'
import axios from 'axios'
import { useContextSnippet } from '../context/ContextApi'
import Loading from './Loading'
function Contact() {
  const apiUrl = useContextSnippet();
  const [contactPageData, setContactPageData] = useState(null);
  useEffect(() => {
    const fetchContactpageData = async () => {
      try {
        const contactpageResponse = await axios.get(`${apiUrl}/pages/48`);
        setContactPageData(contactpageResponse.data);
      } catch (error) {
        console.error('Error fetching Contactpage data:', error);
      }
    };
    fetchContactpageData();
  }, [apiUrl]);
  return (
    <>
      {contactPageData ? (
        <>
          <ContactHero contactPageData={contactPageData} />
          <ContactCard contactPageData={contactPageData} />
          <ContactForm contactPageData={contactPageData} />
          <FooterMain contactPageData={contactPageData} />

        </>
      ):(<Loading/>)}
    </>
  )
}
export default Contact