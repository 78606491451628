import React, { useEffect, useState } from 'react'
import HeroSection from '../HomePage/HeroSection'
import CrouselStudy from '../HomePage/CrouselStudy'
import Brand from '../HomePage/Brand'
import Member from '../HomePage/Member'
import CardSection from '../HomePage/CardSection'
import TeamSection from '../HomePage/TeamSection'
import DiscoverySection from '../HomePage/DiscoverySection'
import FooterMain from '../FooterPage/FooterMain'
import { useContextSnippet } from '../context/ContextApi'
import axios from 'axios'
import Loading from './Loading'
import Review from '../HomePage/Review'
function Home() {
  const apiUrl = useContextSnippet();
  const [homePageData, setHomePageData] = useState(null);
  useEffect(() => {
    const fetchHomepageData = async () => {
      try {
        const homepageResponse = await axios.get(`${apiUrl}/pages/6`);
        setHomePageData(homepageResponse.data);
      } catch (error) {
        console.error('Error fetching homepage data:', error);
      }
    };
    fetchHomepageData();
  }, [apiUrl]);
  return (
    <>
      {homePageData ? (
        <>
          <HeroSection homePageData={homePageData} />
          <CrouselStudy homePageData={homePageData} />
          <Brand homePageData={homePageData} />
          <Review homePageData={homePageData} />
          <Member homePageData={homePageData} />
          <CardSection homePageData={homePageData} />
          <TeamSection homePageData={homePageData} />
          <DiscoverySection homePageData={homePageData} />
          <FooterMain />
        </>
      ) :
        (<Loading />)}
    </>
  );
}
export default Home;