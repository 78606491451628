import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useContextSnippet } from '../context/ContextApi';

function WorkNav() {
    const [portfolioData, setPortfolioData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [uniqueTerms, setUniqueTerms] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiUrl = useContextSnippet();
    
    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/portfolio?_embed&per_page=50&orderby=menu_order&order=asc`);
                const data = response.data;

                const termsSet = new Set();
                data.forEach(item => {
                    const terms = item._embedded?.["wp:term"] || [];
                    terms.forEach(termArray => {
                        termArray.forEach(term => {
                            termsSet.add(term.name.trim().toLowerCase());
                        });
                    });
                });
                setUniqueTerms([...termsSet]);
                setPortfolioData(data);
                setFilteredData(data);
            } catch (error) {
                setError('Error fetching portfolio data.');
                console.error('Error fetching portfolio data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchFilterData();
    }, [apiUrl]);


    useEffect(() => {
        if (selectedFilter === '') {
            setFilteredData(portfolioData);
        } else {
            const filtered = portfolioData.filter(item => {
                const terms = item._embedded?.["wp:term"] || [];
                return terms.some(termArray =>
                    termArray.some(term =>
                        term.name.trim().toLowerCase() === selectedFilter.trim().toLowerCase()
                    )
                );
            });

            setFilteredData(filtered);
        }
        setCurrentPage(1);
    }, [selectedFilter, portfolioData]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const Pagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }
        return <div className="pagination">{pages}</div>;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <section className='work-nav-main'>
            <div className='container'>
                <hr className="work-nav-divider" />
                <div className="work-nav-container">
                    <div className="work-nav-item" onClick={() => setSelectedFilter('')}>All</div>
                    {uniqueTerms.map(term => (
                        <div
                            key={term}
                            className="work-nav-item"
                            onClick={() => setSelectedFilter(term)}
                        >
                            {term.charAt(0).toUpperCase() + term.slice(1)}
                        </div>
                    ))}
                </div>
                <hr className="work-nav-divider" />
                <br />
                <div className='work-nav-content'>
                    {currentItems.length > 0 ? (
                        currentItems.map(item => {
                            const featuredMedia = item._embedded?.["wp:featuredmedia"]?.[0]?.source_url;
                            return (
                                <div key={item.id} className="content-image-work">
                                    <div className='work-image'>
                                        {featuredMedia && <img src={featuredMedia} alt={item.title.rendered} />}
                                    </div>
                                    <div className='work-text'>
                                        <h3>{item.title.rendered}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}></p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div>No items found.</div>
                    )}
                </div>
                <Pagination />
            </div>
        </section>
    );
}

export default WorkNav;





