import React from 'react'

function AboutDiscover({ AboutPageData }) {

    return (
        <>
            <section className="discovery-section">
                <div className='container'>
                    <div className='discovery-main'>
                        <div className="discovery-image">
                            <img srcSet={AboutPageData.acf.about_discover_img}  />
                        </div>
                        <div className="discovery-content">
                            <div className="discovery-description" dangerouslySetInnerHTML={{ __html:AboutPageData.acf.about_discover_title}}/>
                            <a href="https://calendar.app.google/pY9pc87DEZZYaPZk6" target="_blank" rel="noopener noreferrer">
                                <button className="white-button discovery-button" >{AboutPageData.acf.about_discover_button}</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutDiscover