import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContextProvider from './context/ContextProvider';
import About from './components/About';
import OurWork from './components/OurWork';
import Service from './components/Service';
import Contact from './components/Contact';
import Nav from './components/Nav';
import Home from './components/Home';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
function App() {
  return (

    <ContextProvider>
      <Router>
        <>
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-work" element={<OurWork />} />
            <Route path="/service" element={<Service />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </>
      </Router>
    </ContextProvider>
  );
}

export default App;
