import React from 'react';

function CardSection({ homePageData }) {
  const brandsLogo = homePageData.acf.brands_we_work;
  return (
    <section className="logo-card-section">
      <div className='container'>
        <div className="logo-card-header">
          <h3>{homePageData.acf.card_title_section}</h3>
        </div>
        <div className="logo-card-container">
          {brandsLogo.map((card, i) => (
            <div key={i} className="logo-card">
              <img srcSet={card.company_logo || undefined} alt="logo" className="card-image" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CardSection;
