import React from 'react';


function ServiceApproach({ ServicePageData }) {
   let processCards = ServicePageData.acf.process_cards;
    return (
        <section className="service-approach">
            <div className='container'>
                <div className="service-approach-header">
                    <h2 className="service-approach-header-title">{ServicePageData.acf.approach_heading}</h2>
                </div>

                <div className="service-approach-details">
                    {processCards.map((card, i) => (
                        <div className='main-details-number'>
                            <div className="service-approach-numbers">
                                <h1 className="service-approach-number">{i+1}</h1>
                            </div>
                            <div className="service-approach-step">
                                <p className="service-approach-step-description" dangerouslySetInnerHTML={{ __html:card.process_description}}/>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </section>
    );
}

export default ServiceApproach;



