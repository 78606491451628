import React from 'react';

function ServiceBrand({ ServicePageData }) {

    const LearnClickHanlder = () => {
        console.log("Clicked by learn more button")
    }
    let allServicesContent = ServicePageData.acf.all_services;
    return (
        <section className="service-brand-section">
            <div className='container'>
                
                    {allServicesContent.map((service, i) => (
                    <div className="service-brand">
                        <div className="service-brand-image" key={i}>
                            <img srcSet={service.service_image} alt="Brand and Design" /> 
                        </div>
                        <div className="service-brand-content" dangerouslySetInnerHTML={{ __html:service.service_content}} />
                    </div>
                    ))}
                </div>    
        </section>
    );
}
export default ServiceBrand;


